import React, { useEffect, useState } from "react";

function CoreValues() {
  const [theme, setTheme] = useState(
    document.body.getAttribute("data-theme") || "light"
  );

  useEffect(() => {
    // Function to update theme state
    const updateTheme = () =>
      setTheme(document.body.getAttribute("data-theme") || "light");

    // MutationObserver to detect changes in data-theme attribute on <body>
    const observer = new MutationObserver(updateTheme);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-theme"],
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center container my-5">
      <h1 className="text-center display-6 mb-5 fw-bold">Our Core Values</h1>

      <div className="row justify-content-center">
        <div className="col-12 col-md-4 mb-4 d-flex justify-content-center ">
          <CoreValuesCard
            number="01"
            title="Inclusivity"
            description="We believe in creating an environment where all individuals, regardless of gender or disability, are treated with fairness and dignity."
            theme={theme}
          />
        </div>
        <div className="col-12 col-md-4 mb-4 d-flex justify-content-center">
          <CoreValuesCard
            number="02"
            title="Empowerment"
            description="We are dedicated to empowering marginalized groups through education, advocacy, and policy reform."
            theme={theme}
          />
        </div>
        <div className="col-12 col-md-4 mb-4 d-flex justify-content-center">
          <CoreValuesCard
            number="03"
            title="Excellence"
            description="We strive for the highest standards in everything we do, ensuring the best outcomes for our stakeholders and communities."
            theme={theme}
          />
        </div>
        <div className="col-12 col-md-4 mb-4 d-flex justify-content-center">
          <CoreValuesCard
            number="04"
            title="Collaboration"
            description="We work collaboratively with partners, communities, and governments to drive inclusive change and lasting impact."
            theme={theme}
          />
        </div>
        <div className="col-12 col-md-4 mb-4 d-flex justify-content-center">
          <CoreValuesCard
            number="05"
            title="Accountability"
            description="We are accountable for our actions and outcomes, ensuring transparency and integrity in all our operations."
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
}

function CoreValuesCard({ number, title, description, theme }) {
  const cardClass =
    theme === "dark" ? "bg-dark text-white" : "bg-white text-dark";
  return (
    <div>
      <div className={`card text-center p-3 mb-5 ${cardClass}`}>
        <h5 className="card-title pb-2">{number}</h5>
        <h4 className="card-title pb-2">{title}</h4>
        <p className="card-text">{description}</p>
      </div>
    </div>
  );
}

export default CoreValues;
