import { useEffect } from "react";

const UserWayWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.userway.org/widget.js";
    script.setAttribute("data-account", "MvZkYuHdWy");
    script.setAttribute("data-color", "#008000"); // Green
    script.setAttribute("data-secondary-color", "#FF0000"); // Red
    script.setAttribute("data-button-color", "#000000"); // Black
    script.setAttribute("data-text-color", "#FFFFFF"); // White
    script.async = true;
    document.body.appendChild(script);

    const applyCustomStyles = () => {
      const userWayIcon = document.querySelector("#userwayAccessibilityIcon");
      if (userWayIcon) {
        userWayIcon.style.position = "fixed";
        userWayIcon.style.right = "0px";
        userWayIcon.style.zIndex = "1000";

        // Adjust `top` based on screen size
        if (window.innerWidth <= 768) {
          userWayIcon.style.top = "100px";
        } else {
          userWayIcon.style.top = "25px";
        }
      }
    };

    // Apply styles when the widget is loaded
    const interval = setInterval(() => {
      const userWayIcon = document.querySelector("#userwayAccessibilityIcon");
      if (userWayIcon) {
        clearInterval(interval);
        applyCustomStyles();

        // Update styles on window resize
        window.addEventListener("resize", applyCustomStyles);
      }
    }, 100);

    // Clean up on unmount
    return () => {
      clearInterval(interval);
      document.body.removeChild(script);
      window.removeEventListener("resize", applyCustomStyles);
    };
  }, []);

  return null;
};

export default UserWayWidget;
