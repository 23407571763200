import "./css/OurJourney.css";

const OurJourney = () => {
  return (
    <div className="container my-5 journey-section">
      <div className="row align-items-center">
        <div className="col-md-5">
          <img
            src="https://res.cloudinary.com/dawcadg7s/image/upload/v1742455510/uifwhazay1crz7sohz5x.png"
            alt="Inclusive Development Training"
            className="img-fluid rounded"
          />
        </div>
        <div className="col-md-7">
          <h2 className="display-6 fw-medium my-2 text-mobile-center">
            Advocating for Gender and Disability Rights
          </h2>
          <p className="mt-3 lead text-mobile-center">
            GDDC has empowered over 150,000 officers through training programs,
            driving significant advancements in gender and disability policy
            implementation across Kenya. By working with communities,
            stakeholders, and institutions, we continue to build a more
            inclusive society where every individual, regardless of gender or
            ability, can thrive.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurJourney;
