import "./css/Services.css";
import first from "./images/equalityAwards/another group.jpg";
import second from "./images/equalityAwards/another one.jpg";
import third from "./images/equalityAwards/Attendees of kenya equality and inclusion awards 2024.jpg";
import fourth from "./images/equalityAwards/Awards gala.jpg";
import fifth from "./images/equalityAwards/Entertainment.jpg";
import sixth from "./images/equalityAwards/Isaac Mwaura Delivering the Official Opening Speech.jpg";
import seventh from "./images/equalityAwards/Isaac Mwaura during the kenya equality and inclusion awards 2024.jpg"
import eighth from "./images/equalityAwards/Isaac Mwaura.jpg"
import ninth from "./images/equalityAwards/JOOUST receiving an award.jpg"
import tenth from "./images/equalityAwards/Kenya equality and inclusion awards.jpg";
import eleventh from "./images/equalityAwards/Kisii National Polytechnic.jpg"
import twelve from "./images/equalityAwards/lady.jpg"
import thirteen from "./images/equalityAwards/Man smiling during Kenya Equality and Inclusion Awards.jpg"
import fifteen from "./images/equalityAwards/Ministry representative.jpg"
import sixteen from "./images/equalityAwards/Mwaura presenting a trophy.jpg"
import seventeen from "./images/equalityAwards/Next.jpg"
import eighteen from "./images/equalityAwards/Richard Nasongo with representatives from ministry of social protection.jpg"
import nineteen from "./images/equalityAwards/Richard simiyu.jpg"
import twenty from "./images/equalityAwards/tsc.jpg"

export default function AwardsContent() {
  const images = [
    {
      src: first,
      alt: "Award 1",
      caption:
        "Executive Director Richard S Nasong'o and H.E Governor James Ongwae at the 2015 Mainstreaming Awards Gala",
    },
    {
      src: second,
      alt: "Award 2",
      caption: "Gerald receives award for county",
    },
    {
      src: third,
      alt: "Award 3",
      caption:
        "H.E Dep. Governor Gerald Githinji Issuing an Award to Sony Sugar MD Jane Pamella Odhiambo and other staff members",
    },
    {
      src: fourth,
      alt: "Award 4",
      caption: "H.E Hon Ongwae during the 2015 awards",
    },
    {
      src: fifth,
      alt: "Award 5",
      caption:
        "PS Josephta Mukobe Principal Secretary State Department of Special Programmes Issues Award trophy and Certificate to H.E Gerald Githinji Deputy Governor Kiambu County and Esther Ndirangu CEC Gender",
    },
    {
      src: sixth,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: seventh,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: eighth,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: ninth,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: tenth,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: eleventh,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: twelve,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: thirteen,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: fifteen,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: sixteen,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: seventeen,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: eighteen,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: nineteen,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
    {
      src: twenty,
      alt: "Award 6",
      caption:
        "Richard S Nasong'o Issuing an Award to UoN for 1st Runners Up Performance in Mainstreaming Gender in Academia",
    },
  ];
  return (
    <div className="specificService">
      <div className="container pt-5">
        <h2 className="text-center my-4 text-secondary">
          Kenya Equality and Inclusion Awards
        </h2>
        <p>
          The <strong>Kenya Equality and Inclusion Awards</strong> is an annual
          celebration that recognizes exceptional efforts by individuals and
          organizations in advancing equality, inclusion, and accessibility.
          Last year, the event marked its ninth edition, held on{" "}
          <strong>December 20, 2024</strong>, at the prestigious{" "}
          <strong>Boma Hotel, Nairobi</strong>.
        </p>

        <h4 className="text-secondary">Event Overview</h4>
        <p>
          The ceremony brought together leaders from various sectors, including
          government officials, private sector executives, non-governmental
          organizations, and advocates for gender and disability inclusion. The
          event’s theme was{" "}
          <em>
            "Championing Equality and Inclusion for Sustainable Development."
          </em>
        </p>

        <p>
          Highlights included captivating performances by{" "}
          <strong>Kipawa Kimoja Melodies</strong>, thought-provoking keynote
          addresses, and the presentation of awards to outstanding individuals
          and institutions. The event was graced by the presence of:
        </p>
        <ul>
          <li>Hon. Isaac M. Mwaura, Government Spokesperson</li>
          <li>
            Other distinguished guests from the private and public sectors
          </li>
        </ul>

        <h4 className="text-secondary">Award Categories and Winners</h4>
        <p>
          The awards recognized excellence in diverse categories, including
          leadership, education, workplace inclusion, and service delivery.
          Below are the winners for the year 2024:
        </p>
        <ul className="fa-ul">
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Inclusion Crusader and Champion Award:</strong> Hon. Isaac
            M. Mwaura
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Inclusive Education Champions 2024:</strong> JOOUST
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Inclusive Health Provider of the Year:</strong> Mwai Kibaki
            Hospital - KNH
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Inclusive Technical & Vocational Award:</strong> NITA
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>
              Disability Inclusion Personality in the Public Sector:
            </strong>{" "}
            Mr. Moses M. Kamau
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Equality and Inclusion in Research Funding Award:</strong>{" "}
            NRF
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Kenya’s Most Inclusive Public Services Provider:</strong>{" "}
            Huduma Kenya
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Equality and Inclusion Polytechnic Champions:</strong> Kisii
            National Polytechnic
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Inclusive Training and Research Organization:</strong> WRTI
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Women Empowerment Award:</strong> Geothermal Development
            Company
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Inclusive Employment Champions in Kenya:</strong> Riziki
            Source
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>Inclusive GESI in Higher Education for the Year:</strong>{" "}
            USIU Kenya and Maasai Mara University
          </li>
          <li className="mb-3">
            <span className="fa-li">
              <i className="fas fa-trophy text-warning"></i>
            </span>
            <strong>
              Kenya Equality and Inclusion Best Institution of the Year:
            </strong>{" "}
            UoN
          </li>
        </ul>

        <h4 className="text-secondary">Memorable Moments</h4>
        <p>
          The event featured inspiring speeches highlighting the importance of
          gender and disability mainstreaming in achieving sustainable
          development. Hon. Isaac M. Mwaura emphasized the need for continued
          advocacy and collective action to create a more inclusive society.
        </p>

        <p>
          The evening culminated in a gala dinner and entertainment, fostering
          networking opportunities among attendees and celebrating shared
          achievements in promoting equality and inclusion.
        </p>

        <h4 className="text-secondary">Photo Gallery</h4>
        <p>
          Relive the unforgettable moments of the 2024 Kenya Equality and
          Inclusion Awards through our photo gallery:
        </p>

        <div
          id="awardsCarousel"
          className="carousel slide mt-5 pb-5"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            {images.map((_, index) => (
              <button
                type="button"
                key={index}
                data-bs-target="#awardsCarousel"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current={index === 0 ? "true" : "false"}
                aria-label={`Slide ${index + 1}`}
              ></button>
            ))}
          </div>

          <div className="carousel-inner">
            {images.map((image, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
              >
                <img
                  src={image.src}
                  className="d-block w-100"
                  alt={image.alt}
                  style={{
                    objectFit: "cover",
                    objectPosition: "top",
                    maxHeight: "700px",
                    width: "100%",
                  }}
                />
              </div>
            ))}
          </div>

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#awardsCarousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#awardsCarousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <h4 className="text-secondary">Event Highlights</h4>
        <p>
          The Kenya Equality and Inclusion Awards 2024 was not only a
          celebration of achievements but also a platform to reflect on the
          journey towards equality and inclusion in Kenya. It featured
          captivating performances, empowering speeches, and the recognition of
          trailblazers in inclusion.
        </p>
        <p>
          If you missed the live event, you can watch the recorded ceremony
          below.
        </p>

        <div className="responsive-iframe mb-4">
          <iframe
            src="https://www.youtube.com/embed/ciW99YY3dr0?si=-4F6nvIzoMVDIS_l"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
      
          ></iframe>
        </div>

        <h4 className="text-secondary">Downloadable Resources</h4>
        <p>Below are some resources you can download:</p>
        <ul className="list-unstyled">
          <li>
            <a
              href="resources/Kenya Equality and Inclusion Awards 2024 Registration Form.pdf"
              download
              className="btn btn-secondary my-2"
              aria-label="Download the registration form in PDF format"
            >
              Download Registration Form
            </a>
          </li>
          <li>
            <a
              href="resources/PS Joseph Motari Speech at the 2024 Kenya Equality and Inclusion Awards.pdf"
              download
              className="btn btn-secondary my-2"
              aria-label="Download the speech by the State Department of Social Protection and Senior Citizen Affairs in PDF format"
            >
              Download Speech
            </a>
          </li>
          <li>
            <a
              href="resources/Kenya Equality and Inclusion Awards 2024 Winners.pdf"
              download
              className="btn btn-secondary my-2"
              aria-label="Download the winners list in PDF format"
            >
              Download Winners List
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
