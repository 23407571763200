import React from "react";
import "./css/Services.css";

const eventsData = [
  {
    id: 1,
    title:
      "Implementing the Brailed Service Charters and Accessible ICTs for Persons with Disabilities at the Workplace ",
    dates: "30th Oct. to 1st Nov. 2024 ",
    venue: "Kandiani Hotel, Machakos ",
    cost: "55,700/-",
    dateObject: new Date("2024-11-02"),
  },
  {
    id: 2,
    title: "Implement Gender Policies and Addressing GBV at the Workplace",
    dates: "6th to 8th Nov 2024",
    venue: "Vittoria Suittes Kisumu",
    cost: "55,700/-",
    dateObject: new Date("2024-11-09"),
  },
  {
    id: 3,
    title:
      "ICTs and Website Accessibility Upgrade to enable access and use by PWDs ",
    dates: "13th to 15th Nov. 2024",
    venue: "Naivasha, Eserian Hotel ",
    cost: "55,700/-",
    dateObject: new Date("2024-11-16"),
  },
  {
    id: 4,
    title:
      "The Inclusive Service Delivery Workshop for Ease of Access Mandates through KS ISO 21542-2011 and Fundamental Standards of KS 2952 on Accessibility of ICT Products and Services.",
    dates: "18th to 20th Nov. 2024",
    venue: "Machakos SEO",
    cost: "65,700/-",
    dateObject: new Date("2024-11-21"),
  },
  {
    id: 5,
    title: "AGPO Implementation Workshop ",
    dates: "20th to 22nd Nov. 2024 ",
    venue: "Machakos AL Hotel",
    cost: "55,700/-",
    dateObject: new Date("2024-11-23"),
  },
  {
    id: 6,
    title:
      "HR Workshop for Inclusive Competency Development for Employees with Disabilities",
    dates: "19th to 22th Nov. 2024 ",
    venue: "Machakos AL Hotel",
    cost: "85,000/-",
    dateObject: new Date("2024-11-23"),
  },
  {
    id: 7,
    title:
      "The Gender and Disability Mainstreaming Legal and Policy Compliance Congress for State Corporations Board & Council Directors and Members",
    dates: "25th to 29th Nov. 2024 ",
    venue: "Pride Inn Paradise, Shanzu",
    cost: "85,612/-",
    dateObject: new Date("2024-11-30"),
  },
  {
    id: 8,
    title:
      "Designing and Implementing and Domesticating the National Inclusive Data Charter Action Plan",
    dates: "2nd to 4th Dec 2024 ",
    venue: "Machakos, SEO Hotel",
    cost: "64,612",
    dateObject: new Date("2024-12-05"),
  },
  {
    id: 9,
    title:
      "Accessible ICTs and Website Upgrade to conform with the WCAG 2.1, KEBS Standards on ICT Accessibility and other Global Best Practices  ",
    dates: "11th to 13th Dec 2024 ",
    venue: "Machakos AL Hotel",
    cost: "64,612/-",
    dateObject: new Date("2024-12-14"),
  },
  {
    id: 10,
    title: "Inclusive Public Relations Programs and Communications",
    dates: "16th to 18th Dec 2024",
    venue: "Nairobi EKA Hotel",
    cost: "64,612/-",
    dateObject: new Date("2024-12-19"),
  },
  {
    id: 11,
    title: "The Kenya Equality and Inclusion Awards 2024",
    dates: "20th Dec. 2024",
    venue: "Boma Hotel, Nairobi",
    cost: "100,000/- for 10Pax ",
    dateObject: new Date("2024-12-21"),
  },
];

const filterUpcomingEvents = (events) => {
    const currentDate = new Date();
    return events.flatMap(event =>
        event.sessions
            ? event.sessions.filter(session => session.dateObject > currentDate)
            : event.dateObject > currentDate ? [event] : []
    );
};

export default function EventsContent() {
    const upcomingEvents = filterUpcomingEvents(eventsData);

    return (
        <div className="specificService">
            <div className="container py-5">
                <h2 className="text-center mb-5">UPCOMING EVENTS: THE 2024-2025 GENDER AND DISABILITY MAINSTREAMING TRAINING AND SENSITIZATION
                    CALENDAR
                </h2>

                {upcomingEvents.length > 0 ? (
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>Title</th>
                                <th>Date</th>
                                <th>Venue</th>
                                <th>Cost (Ksh.)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {upcomingEvents.map((event, index) => (
                                event.sessions ? (
                                    event.sessions.map((session, idx) => (
                                        <tr key={`${index}-${idx}`}>
                                            <td>{event.title}</td>
                                            <td>{session.date}</td>
                                            <td>{session.venue}</td>
                                            <td>{session.cost}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr key={event.id}>
                                        <td>{event.title}</td>
                                        <td>{event.dates}</td>
                                        <td>{event.venue}</td>
                                        <td>{event.cost !== "N/A" ? `Ksh. ${event.cost}` : "Not Applicable"}</td>
                                    </tr>
                                )
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <h2 className="text-center">No upcoming events at this time.</h2>
                )}
            </div>
        </div>
    );
}
