import Navbar from "./Navbar";
import Services from "./Services";
import OurImpact from "./OurImpact";
import CoreValues from "./CoreValues";
import CallToAction from "./CallToAction";
import Footer from "./Footer";

export default function ServicesOverview() {
    return (
        <>
            <Navbar />
            <div className="serviceOverviewHero">
                <Services />
            </div>
            <OurImpact />
            <CoreValues />
            <CallToAction />
            <Footer />
        </>
    );
}
