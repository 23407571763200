import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const LinkItem = ({ href, name, children, linkClass }) => {
  return (
    <li>
      <Link
        to={href}
        name={name}
        className={`${linkClass} text-decoration-none`}
      >
        <i className="fas fa-angle-right me-2"></i>
        {children}
      </Link>
    </li>
  );
};

const Footer = () => {
  const getCurrentTheme = () =>
    document.body.getAttribute("data-theme") || "light";
  const [theme, setTheme] = useState(getCurrentTheme());

  useEffect(() => {
    const updateTheme = () => setTheme(getCurrentTheme());

    const storageListener = () => {
      setTheme(localStorage.getItem("theme") || "light");
    };
    window.addEventListener("storage", storageListener);

    const observer = new MutationObserver(updateTheme);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-theme"],
    });

    return () => {
      window.removeEventListener("storage", storageListener);
      observer.disconnect();
    };
  }, []);

  const footerClass =
    theme === "dark" ? "bg-dark text-light" : "bg-light text-dark";
  const headingClass = theme === "dark" ? "text-light" : "text-dark";
  const textClass = theme === "dark" ? "text-light" : "text-dark";
  const linkClass = theme === "dark" ? "text-white" : "text-dark";

  const isDarkMode = document.body.getAttribute("data-theme") === "dark";
  
  const textMutedStyle = {
    color: isDarkMode ? "#f2f2f2" : "#6c757d",
  };

  return (
    <footer className={`py-5 ${footerClass}`}>
      <div className="container">
        <div className="row text-start">
          <div className="col-lg-3 mb-4">
            <h5 className={`${headingClass} fw-bold`}>About Us</h5>
            <p className={`${textClass}`}>
              GDDC Kenya is dedicated to driving gender and disability
              mainstreaming across Kenya. We focus on building inclusive
              policies and empowering communities through education and
              advocacy.
            </p>
          </div>

          <div className="col-lg-3 mb-4">
            <h5 className={`${headingClass} fw-bold`}>Quick Links</h5>
            <ul className="list-unstyled">
              <LinkItem href="/" name="Home" linkClass={linkClass}>
                Home
              </LinkItem>
              <LinkItem href="/about" name="About" linkClass={linkClass}>
                About
              </LinkItem>
              <LinkItem href="/services" name="Services" linkClass={linkClass}>
                Services
              </LinkItem>
              <LinkItem href="/events" name="Events" linkClass={linkClass}>
                Events
              </LinkItem>
              <LinkItem href="/contact" name="Contact" linkClass={linkClass}>
                Contact
              </LinkItem>
              <LinkItem
                href="/terms-and-conditions"
                name="Terms and Conditions"
                linkClass={linkClass}
              >
                Terms and Conditions
              </LinkItem>
              <LinkItem
                href="/privacy-policy"
                name="Privacy Policy"
                linkClass={linkClass}
              >
                Privacy Policy
              </LinkItem>
            </ul>
          </div>

          <div className="col-lg-3 mb-4">
            <h5 className={`${headingClass} fw-bold`}>Offerings</h5>
            <ul className="list-unstyled">
              <LinkItem
                href="/services/training-and-workshops"
                name="In-House Training"
                linkClass={linkClass}
              >
                In-House Training and Facilitation
              </LinkItem>
              <LinkItem
                href="/services/policy-development"
                name="Policy Development"
                linkClass={linkClass}
              >
                Policy Development
              </LinkItem>
              <LinkItem
                href="/services/certification"
                name="Certification"
                linkClass={linkClass}
              >
                Certification
              </LinkItem>
              <LinkItem
                href="/services/training-and-workshops"
                name="GEDICON Workshops"
                linkClass={linkClass}
              >
                GEDICON Conferences, Seminars, and Workshops
              </LinkItem>
              <LinkItem
                href="/services/research-and-publications"
                name="Research and Publications"
                linkClass={linkClass}
              >
                Research and Publications
              </LinkItem>
              <LinkItem
                href="/awards"
                name="Kenya Equality and Inclusion Awards"
                linkClass={linkClass}
              >
                Kenya Equality and Inclusion Awards
              </LinkItem>
            </ul>
          </div>

          <div className="col-lg-3 mb-4">
            <h5 className={`${headingClass} fw-bold`}>Contact Info</h5>
            <p className={`${textClass} mb-1`}>
              <i className="fas fa-map-marker-alt me-2"></i> P.O Box 2221-00200,
              Nairobi
            </p>
            <p className={`${textClass} mb-1`}>
              <i className="fas fa-phone me-2"></i> 0726346929 / 0722451844
            </p>
            <p className={`${textClass}`}>
              <i className="fas fa-envelope me-2"></i> info@gddckenya.org
            </p>
            <div className="d-flex mt-2 justify-content-start">
              <a
                href="https://www.facebook.com/p/Gender-and-Disability-Development-Centre-100067024896772/"
                className="me-4 social-icon text-secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f rounded-circle fa-lg"></i>
                <span className="sr-only">Facebook</span>
              </a>
              <a
                href="https://x.com/gddckenya?lang=en"
                className="me-4 social-icon text-secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter rounded-circle fa-lg"></i>
                <span className="sr-only">Twitter</span>
              </a>
              <a
                href="https://whatsapp.com/channel/0029VanEt3xGzzKU5MAd8s1s"
                className="social-icon text-secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-whatsapp rounded-circle fa-lg"></i>
                <span className="sr-only">WhatsApp</span>
              </a>
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          <small className={`${textClass}`}>
            &copy; 2024 Gender and Disability Development Centre. All rights
            reserved. Designed by
            <a
              href="https://www.linkedin.com/in/dr-cedric-olunga"
              className="ms-2"
              style={textMutedStyle}
              target="_blank"
              rel="noopener noreferrer"
            >
              Olunga Tech Creations
            </a>
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
