import React, { useEffect } from "react";
import "./css/Services.css";

const resources = [
  {
    name: "GDDC Calendar 2024-2025 Q1 Q2",
    file: "/resources/THE 2024-2025 GENDER AND DISABILITY MAINSTREAMING TRAINING AND SENSITIZATION CALENDAR..pdf",
    description:
      "Explore GDDC Kenya’s official event calendar for the latest training, workshops, and sensitization events.",
  },
  {
    name: "GDDC Souvenir Magazine",
    file: "/resources/GDDC Souvenir Magazine 2017 4 web.pdf",
    description:
      "Take a look at highlights from GDDC’s milestones and achievements in gender and disability inclusion.",
  },
  {
    name: "National Gender and Disability Mainstreaming Excellency Awards",
    file: "/resources/DISABILITY SUPPLEMENT TEMPLATE.pdf",
    description:
      "A comprehensive document on Kenya's 3rd National Gender and Disability Mainstreaming Excellency Awards, detailing efforts, achievements, and standards in disability inclusivity and gender equity. Highlights include policy benchmarks, sector champions, and organizational advancements toward an inclusive society.",
  },
  {
    name: "Past Winners",
    file: "/resources/pastwinners.pdf",
    description:
      "View the list of past winners recognized for their exceptional contributions to gender and disability advancement.",
  },
  {
    name: "Diverse Kenya Media Invitation",
    file: "/resources/DiverseKenya 2012 Media invitation.docx",
    description:
      "An official media invitation showcasing Kenya’s commitment to diverse and inclusive representation.",
  },
  {
    name: "Disability Awareness Creation Booklet",
    file: "/resources/MoLSP-Disability-Awareness-Creation-Booklet-2021.pdf",
    description:
      "Guidelines and resources for creating awareness on disability inclusion and rights in workplaces.",
  },
  {
    name: "IACC Workplan",
    file: "/resources/IACC WORKPLAN.pdf",
    description:
      "National action plan for implementation of global disability summit",
  },
  {
    name: "Kenya Equality and Inclusion Awards 2024 Registration Form",
    file: "/resources/Kenya Equality and Inclusion Awards 2024 Registration Form.pdf",
    description:
      "The registration form completed by attendees, including details such as name, institution, email, phone number, and signature.",
  },
  {
    name: "Speech by State Department for Social Protection and Senior Citizen Affairs",
    file: "/resources/PS Joseph Motari Speech at the 2024 Kenya Equality and Inclusion Awards.pdf",
    description:
      "The official speech delivered by State Department for Social Protection and Senior Citizen Affairs at the 2024 Kenya Equality and Inclusion Awards.",
  },
  {
    name: "Kenya Equality and Inclusion Awards 2024 Winners",
    file: "/resources/Kenya Equality and Inclusion Awards 2024 Winners.pdf",
    description:
      "A detailed list of winners recognized at the 2024 Kenya Equality and Inclusion Awards for their outstanding contributions to equality and inclusion.",
  },
];

function ResourcesContent() {
  const handleThemeChange = () => {
    const isDarkMode = document.documentElement.classList.contains("dark");
    const cards = document.querySelectorAll(".card");

    // Update styles based on theme
    cards.forEach((card) => {
      if (isDarkMode) {
        card.style.backgroundColor = "#333"; // Dark mode background
        card.style.color = "#fff"; // Light text for dark mode
      } else {
        card.style.backgroundColor = "#fff"; // Light mode background
        card.style.color = "#000"; // Dark text for light mode
      }
    });
  };

  useEffect(() => {
    // MutationObserver to watch for changes in the theme (light/dark)
    const observer = new MutationObserver(() => {
      handleThemeChange();
    });

    // Start observing for class changes on the document element
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    // Initial theme setup
    handleThemeChange();

    // Cleanup the observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="specificService">
      <div className="container py-5">
        <h2 className="text-center mb-5">GDDC Kenya Resources</h2>
        <div className="row">
          {resources.map((resource, index) => (
            <div key={index} className="col-md-6 mb-4">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">{resource.name}</h5>
                  <p className="card-text">{resource.description}</p>
                  <a
                    href={resource.file}
                    download
                    className="btn btn-secondary"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ResourcesContent;