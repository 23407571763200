import { useEffect, useState } from "react";

const OurImpact = () => {
  const [satisfaction, setSatisfaction] = useState(0);
  const [experience, setExperience] = useState(0);
  const [projects, setProjects] = useState(0);
  const [employees, setEmployees] = useState(0);
  const [theme, setTheme] = useState(
    document.body.getAttribute("data-theme") || "light"
  );

  useEffect(() => {
    const incrementValues = (setValue, target, delay) => {
      let count = 0;
      const interval = setInterval(() => {
        if (count < target) {
          count++;
          setValue(count);
        } else {
          clearInterval(interval);
        }
      }, delay);
    };

    incrementValues(setSatisfaction, 98, 80);
    incrementValues(setExperience, 12, 500);
    incrementValues(setProjects, 120, 60);
    incrementValues(setEmployees, 80, 100);
  }, []);

  useEffect(() => {
    const updateTheme = () =>
      setTheme(document.body.getAttribute("data-theme") || "light");

    const observer = new MutationObserver(updateTheme);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-theme"],
    });

    return () => observer.disconnect();
  }, []);

  const containerClass =
    theme === "dark" ? "bg-dark text-light" : "bg-light text-dark";
  const headingClass = theme === "dark" ? "text-white" : "text-dark";
  const statClass = theme === "dark" ? "text-white" : "text-dark";

  return (
    <div className={`$container-fluid`}>
      <div className={`container text-center py-5 ${containerClass} rounded-4`}>
        <h2
          className={`mb-5 display-6 text-secondary fw-semibold ${headingClass}`}
        >
          Measuring Our Impact
        </h2>
        <div className="row">
          <div className="col-md-3">
            <h3 className={`${statClass} fs-4`}>Client Satisfactory Rate</h3>
            <h4 className={`${statClass} fw-bold h2`}>{satisfaction}%</h4>
          </div>
          <div className="col-md-3">
            <h3 className={`${statClass} fs-4`}>Years of Experience</h3>
            <h4 className={`${statClass} fw-bold h2`}>{experience}</h4>
          </div>
          <div className="col-md-3">
            <h3 className={`${statClass} fs-4`}>Projects Completed</h3>
            <h4 className={`${statClass} fw-bold h2`}>{projects}</h4>
          </div>
          <div className="col-md-3">
            <h3 className={`${statClass} fs-4`}>Happy Employees</h3>
            <h4 className={`${statClass} fw-bold h2`}>{employees}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurImpact;
