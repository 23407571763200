import { useEffect, useState } from "react";

export default function WhyChooseUs() {
  const getCurrentTheme = () =>
    document.body.getAttribute("data-theme") || "light";
  const [theme, setTheme] = useState(getCurrentTheme());

  useEffect(() => {
    const updateTheme = () => setTheme(getCurrentTheme());
    const storageListener = () => {
      setTheme(localStorage.getItem("theme") || "light");
    };
    window.addEventListener("storage", storageListener);

    const observer = new MutationObserver(updateTheme);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-theme"],
    });

    return () => {
      window.removeEventListener("storage", storageListener);
      observer.disconnect();
    };
  }, []);

  const sectionClass =
    theme === "dark" ? "bg-dark text-light" : "bg-light text-dark";
  const headingClass = theme === "dark" ? "text-light" : "text-dark";
  const textClass = theme === "dark" ? "text-light" : "text-dark";

  return (
    <div className={`container-fluid ${sectionClass} py-5`}>
      <div className="container">
        <div
          className="row align-items-center text-center text-lg-start"
          style={{ minHeight: "60vh" }}
        >
          <div className="col-lg-6 mb-4">
            <h3 className={`display-6 fw-bold ${headingClass}`}>
              Why Choose GDDC?
            </h3>
            <p className={`lead ${textClass}`}>
              At GDDC, we lead the charge in promoting inclusive development by
              mainstreaming gender and disability policies. Here's why we are
              the best partner in fostering social equity and inclusion.
            </p>
          </div>

          <div className="col-lg-6 d-flex flex-column align-items-center align-items-lg-start">
            <div className="mb-4 text-center text-lg-start">
              <h4 className={`fw-bold ${headingClass}`}>
                Inclusive Policy Development
              </h4>
              <p className={textClass}>
                We work with governments, institutions, and stakeholders to
                develop policies that prioritize gender equality and disability
                rights, ensuring an inclusive society.
              </p>
            </div>
            <hr className="d-lg-none" />

            <div className="mb-4 text-center text-lg-start">
              <h4 className={`fw-bold ${headingClass}`}>
                Capacity Building Programs
              </h4>
              <p className={textClass}>
                GDDC has trained over 150,000 officers across various sectors,
                empowering them with skills and knowledge to champion gender and
                disability mainstreaming.
              </p>
            </div>
            <hr className="d-lg-none" />

            <div className="mb-4 text-center text-lg-start">
              <h4 className={`fw-bold ${headingClass}`}>
                Community Engagement
              </h4>
              <p className={textClass}>
                We engage communities through workshops and outreach programs,
                driving awareness and encouraging grassroots participation in
                our inclusive development initiatives.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
