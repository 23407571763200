import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ id, title, description, image, className }) => {
  const [theme, setTheme] = useState(
    document.body.getAttribute("data-theme") || "light"
  );

  useEffect(() => {
    const handleThemeChange = () => {
      setTheme(document.body.getAttribute("data-theme"));
    };

    const observer = new MutationObserver(() => handleThemeChange());
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["data-theme"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={className}>
      <div
        className={`card h-100 d-flex flex-column shadow-lg ${
          theme === "dark" ? "bg-dark text-light" : "bg-light text-dark"
        }`}
      >
        <img
          src={image}
          alt={title}
          className="card-img-top"
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
            objectPosition: "top",
          }}
        />
        <div className="card-body d-flex flex-column">
          <h3 className="card-title">{id}</h3>
          <h3 className="card-title">{title}</h3>
          <p className="card-text">{description}</p>
          <Link
            to={`/services/${title.toLowerCase().replace(/\s+/g, "-")}`}
            className="btn btn-secondary text-light mt-auto"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
