import "./css/Services.css";

const Membership = () => {
  const isDarkMode = document.body.getAttribute("data-theme") === "dark";

  // Define inline styles for readability
  const textMutedStyle = {
    color: isDarkMode ? "#f2f2f2" : "#6c757d",
  };
    return (
      <div className="specificService">
        <div className="container py-5">
          <div className="text-center mb-4">
            <h2 className="fw-semibold mt-3 text-secondary">
              Join Our Membership
            </h2>
            <p style={textMutedStyle}>
              Be part of a community dedicated to advancing gender equality and
              supporting persons with disabilities in Kenya.
            </p>
          </div>

          {/* Membership Benefits */}
          <div className="row mb-5">
            <div className="col-lg-6 mb-3">
              <h4 className="fw-semibold text-secondary">
                Benefits of Membership
              </h4>
              <ul style={textMutedStyle}>
                <li>
                  Free interaction with GDDC professional fraternity through our
                  open forums and Monthly talks.
                </li>
                <li>
                  Free connection to Gender and Disability experts drawing from
                  our broad network base of researchers, local and international
                  universities, fellows and students regionally and
                  internationally.
                </li>
                <li>
                  Free information on local or international upcoming seminars,
                  college offerings, fellowships, scholarships for members
                  interested in Gender and Disability courses.
                </li>
                <li>Free access to GDDC member's directory.</li>
                <li>
                  Information on job vacancies and placements of qualified
                  Persons with Disabilities
                </li>
                <li>
                  Membership certificate and Identification that certifies
                  members as Gender and Disability professionals across all
                  sectors.
                </li>
                <li>
                  Free participation in our bi annual Persons With Disability
                  Festival "Sote Pamoja", set aside to help person's with
                  Disability access micro-finance, education as we raise
                  awareness on Disability mainstreaming in Kenya.
                </li>
                <li>
                  Participation in the "African Girl" set aside to address
                  Female Genital Mutilation (FGM), Rape as a tool of violence in
                  Eastern and Sub-Saharan Africa.
                </li>
                <li>
                  Free subscription to Publications, research and newsletters
                  informing institutions/individuals on the progress of Gender
                  or Disability mainstreaming not only in Kenya but regionally
                  and internationally.
                </li>
                <li>
                  Access to Gender and Disability mainstreaming portal for
                  members to discuss issues and share experiences online.
                </li>
                <li>
                  Free access to our resource centre, journals, Gender and
                  disability mainstreaming for those interested in Gender
                  analysis and Disability research and development.
                </li>
                <li>
                  Use of membership credentials example GDDC for full members
                </li>
                <li>
                  Membership certificate and identification for state
                  corporations, private and public institutions as Gender and
                  Disability Responsive environments.
                </li>
                <li>
                  Free facilitation in the interpretation of sex disaggregated
                  for member state institutions that desire to track gender and
                  disability mainstreaming actions in the institution.
                </li>
                <li>
                  State, public and private corporations are eligible to a 10%
                  discount in the identification and setting of sector specific
                  Gender targets and indicators for the adoption of a Gender and
                  Disability policy document
                </li>
                <li>
                  Discounted fee for identification of institutional gender
                  needs,gaps.priorities for various sectors Example Educational
                  institutions, Agriculture, Energy, Tourism and horticultural
                  Tourism that seek to adopt a Gender mainstreaming policy.
                </li>
                <li>
                  Gender and Disability in-house trainings at a discounted fee
                  for every individual nominated to participate in in-house
                  trainings facilitated by GDDC,
                </li>
                <li>
                  Discounted fees for Gender analysis of development planning at
                  the county and national level.
                </li>
                <li>
                  Customization of Gender and disability in-house training
                  tailored to equip employees of various institutions, Gender
                  committee members and/or relevant nominated participants on
                  how to conduct Gender and Disability Monitoring and
                  Evaluations as guided by best practice.
                </li>
                <li>
                  Free advice on the integration of disability policy action
                  plans into the institutions' recruitment and HR manual,
                </li>
                <li>
                  Participation during our annual Gender and Disability
                  mainstreaming awards where we award and recognize the best
                  performers in mainstreaming Gender and Disability locally and
                  regionally. This award will also recognize member institutions
                  that equally enable the equitable and fair treatment of men,
                  women and Persons with Disabilities (PWD's) within the
                  workforce.
                </li>
                <li>
                  Partnerships together with member organizations to create
                  awareness on Gender Based Violence and the need to help
                  victims deal with the stigma associated with sexual harassment
                  and gender based violence.
                </li>
                <li>
                  Issue of The Diversity Kenya Quarterly Magazine on subsidized
                  subscribed rate.
                </li>
              </ul>
            </div>

            {/* Membership Requirements */}
            <div className="col-lg-6">
              <h4 className="fw-semibold text-secondary">
                Membership Requirements
              </h4>
              <ul style={textMutedStyle}>
                <li>Commitment to GDDC's mission and values</li>
                <li>Participation in at least one event per year</li>
                <li>Active involvement in GDDC Kenya’s activities</li>
                <li>Nominal annual membership fee</li>
              </ul>

              <h4 className="fw-semibold text-secondary">
                Modalities of Payment for GDDC Membership
              </h4>
              <ul style={textMutedStyle}>
                <li>
                  Direct transfer to GDDC Bank Account in the UK, please ask the
                  bank account details info@gddckenya.org
                </li>
                <li>
                  Bank Draft made payable to: GENDER AND DISABILITY DEVELOPMENT
                  CENTRE.
                </li>
                <li>
                  Cheques and Postal or Money orders payable to: GENDER AND
                  DISABILITY DEVELOPMENT CENTRE.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Membership;
