import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function SEO() {
  const location = useLocation();

  const seoData = {
    "/": {
      title: "Home | Gender and Disability Development Centre",
      description:
        "Welcome to GDDC, promoting gender equality and disability inclusion through research, policy development, and advocacy.",
      keywords:
        "gender equality, disability inclusion, advocacy, policy development",
      canonical: "https://www.gddckenya.org/",
    },
    "/about": {
      title: "About | Gender and Disability Development Centre",
      description:
        "Learn more about the Gender and Disability Development Centre, our mission, vision, and impact.",
      keywords: "about us, disability rights, gender advocacy, social impact",
      canonical: "https://www.gddckenya.org/about",
    },
    "/services": {
      title: "Services | Gender and Disability Development Centre",
      description:
        "Explore our range of services including research, training, certification, policy development, and consultancy.",
      keywords:
        "services, research, training, workshops, policy development, consultancy",
      canonical: "https://www.gddckenya.org/services",
    },
    "/services/research-and-publications": {
      title:
        "Research and Publications | Gender and Disability Development Centre",
      description:
        "Access our research and publications on gender and disability inclusion, policy insights, and case studies.",
      keywords:
        "research, publications, disability studies, gender equality research",
      canonical: "https://www.gddckenya.org/services/research-and-publications",
    },
    "/services/training-and-workshops": {
      title:
        "Training and Workshops | Gender and Disability Development Centre",
      description:
        "Join our training and workshops on gender equality, disability inclusion, and advocacy strategies.",
      keywords: "training, workshops, skill development, inclusive leadership",
      canonical: "https://www.gddckenya.org/services/training-and-workshops",
    },
    "/services/certification": {
      title: "Certification | Gender and Disability Development Centre",
      description:
        "Get certified in gender and disability inclusion through our accredited certification programs.",
      keywords:
        "certification, disability inclusion training, gender equality certification",
      canonical: "https://www.gddckenya.org/services/certification",
    },
    "/services/policy-development": {
      title: "Policy Development | Gender and Disability Development Centre",
      description:
        "We assist in policy development for gender and disability inclusion at organizational and governmental levels.",
      keywords:
        "policy development, advocacy, gender policy, disability rights laws",
      canonical: "https://www.gddckenya.org/services/policy-development",
    },
    "/services/consultancy": {
      title: "Consultancy | Gender and Disability Development Centre",
      description:
        "Our consultancy services help businesses and organizations implement effective gender and disability inclusion strategies.",
      keywords:
        "consultancy, business inclusion, disability advocacy, workplace equality",
      canonical: "https://www.gddckenya.org/services/consultancy",
    },
    "/events": {
      title: "Events | Gender and Disability Development Centre",
      description:
        "Stay updated on our upcoming events, conferences, and workshops promoting gender and disability inclusion.",
      keywords:
        "events, conferences, gender inclusion events, disability workshops",
      canonical: "https://www.gddckenya.org/events",
    },
    "/membership": {
      title: "Membership | Gender and Disability Development Centre",
      description:
        "Become a member and support our mission for gender equality and disability inclusion.",
      keywords:
        "membership, join us, gender advocacy, disability rights support",
      canonical: "https://www.gddckenya.org/membership",
    },
    "/contact": {
      title: "Contact | Gender and Disability Development Centre",
      description:
        "Get in touch with us for inquiries, partnerships, or support.",
      keywords:
        "contact us, gender inclusion help, disability support, reach out",
      canonical: "https://www.gddckenya.org/contact",
    },
    "/terms-and-conditions": {
      title: "Terms and Conditions | Gender and Disability Development Centre",
      description:
        "Read our terms and conditions regarding our services and policies.",
      keywords: "terms, conditions, legal policies, website terms",
      canonical: "https://www.gddckenya.org/terms-and-conditions",
    },
    "/privacy-policy": {
      title: "Privacy Policy | Gender and Disability Development Centre",
      description:
        "Understand how we protect your data and privacy at the Gender and Disability Development Centre.",
      keywords:
        "privacy policy, data protection, user privacy, GDPR compliance",
      canonical: "https://www.gddckenya.org/privacy-policy",
    },
    "/awards": {
      title: "Awards | Gender and Disability Development Centre",
      description:
        "Discover our awards recognizing contributions to gender equality and disability inclusion.",
      keywords:
        "awards, recognition, gender equality awards, disability inclusion awards",
      canonical: "https://www.gddckenya.org/awards",
    },
    "/resources": {
      title: "Resources | Gender and Disability Development Centre",
      description:
        "Browse our educational resources on gender and disability inclusion.",
      keywords: "resources, educational materials, disability inclusion tools",
      canonical: "https://www.gddckenya.org/resources",
    },
    // "/national-equality-and-inclusion-awards": {
    //   title:
    //     "National Equality and Inclusion Awards | Gender and Disability Development Centre",
    //   description:
    //     "The National Equality and Inclusion Awards honor individuals and organizations championing inclusivity.",
    //   keywords:
    //     "national awards, inclusion awards, gender equality recognition",
    // },
  };

const pageData = seoData[location.pathname] || {
  title: "Gender and Disability Development Centre",
  description:
    "Championing gender and disability inclusion for a better, more inclusive world.",
  canonical: "https://www.gddckenya.org/",
};

useEffect(() => {
  document.title = pageData.title;
}, [pageData.title]);

  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{pageData.title}</title>
      <meta name="description" content={pageData.description} />
      <meta name="author" content="Gender and Disability Development Centre" />
      <link rel="canonical" href={pageData.canonical} />
      <meta property="og:title" content={pageData.title} />
      <meta property="og:description" content={pageData.description} />
      <meta property="og:url" content={pageData.canonical} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://www.gddckenya.org/logo.png" />
      <meta name="twitter:title" content={pageData.title} />
      <meta name="twitter:description" content={pageData.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://www.gddckenya.org/logo.png" />
    </Helmet>
  );
}
