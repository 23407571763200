import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "./images/GDDC LOGO.png";

export default function Navbar() {
  const [navOpen, setNavOpen] = useState(false);
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }, []);

  const handleTogglerClick = () => {
    setNavOpen(!navOpen);
  };

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    document.body.setAttribute("data-theme", theme);
  }, [theme]);

  const navbarClasses = "navbar navbar-expand-lg px-md-5 shadow-lg fixed-top";

  return (
    <nav
      className={`${navbarClasses} ${
        theme === "dark" ? "bg-dark" : "bg-white"
      }`}
    >
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img
            src={logo}
            alt="logo"
            height="100px"
            className="rounded"
            loading="lazy"
            title="GDDC Kenya logo"
          />
        </Link>

        <div className="d-flex align-items-center">
          <button
            className="btn btn-outline-secondary me-2 d-lg-none"
            onClick={toggleTheme}
            aria-label="Toggle Theme"
            style={{ border: "none" }}
          >
            <i
              className={`fas ${theme === "light" ? "fa-moon" : "fa-sun"}`}
            ></i>
          </button>

          <button
            className="navbar-toggler"
            type="button"
            onClick={handleTogglerClick}
            style={{
              outline: "none",
              boxShadow: "none",
              color: theme === "dark" ? "white" : "black",
            }}
            aria-expanded={navOpen ? "true" : "false"}
          >
            <i className={`fas ${navOpen ? "fa-xmark" : "fa-bars"} fa-lg`}></i>
          </button>
        </div>

        <div
          className={`collapse navbar-collapse ${navOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto">
            <NavigationLink name="Home" link="/" />
            <NavigationLink name="About" link="/about" />
            <ServicesDropdown />
            <NavigationLink name="Events" link="/events" />
            <NavigationLink name="Membership" link="/membership" />
            <NavigationLink name="Awards" link="/awards" />
            <NavigationLink name="Resources" link="/resources" />
            <NavigationLink name="Contact" link="/contact" />
          </ul>
        </div>

        {/* Theme Switcher (Larger screens) */}
        <div className="d-none d-lg-flex align-items-center ms-auto">
          <button
            className="btn btn-outline-secondary"
            onClick={toggleTheme}
            aria-label="Toggle Theme"
            style={{ border: "none" }} // Remove border
          >
            <i
              className={`fas ${theme === "light" ? "fa-moon" : "fa-sun"}`}
            ></i>
          </button>
        </div>
      </div>
    </nav>
  );
}

function NavigationLink(props) {
  return (
    <li className="nav-item">
      <NavLink
        to={props.link}
        className={({ isActive }) =>
          `nav-link me-4 text-uppercase fw-bold ${isActive ? "active" : ""}`
        }
      >
        {props.name}
      </NavLink>
    </li>
  );
}

function ServicesDropdown() {
  const [isHovered, setIsHovered] = useState(false);

  const dropdownMenuStyles = {
    display: isHovered ? "block" : "none",
    position: "absolute",
    top: "100%",
    left: "0",
    zIndex: "1000",
    minWidth: "10rem",
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <li
      className="nav-item dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      <Link
        to="/services"
        className="nav-link dropdown-toggle me-4 text-uppercase fw-bold d-flex align-items-center"
        id="servicesDropdown"
        role="button"
        aria-expanded={isHovered ? "true" : "false"}
      >
        Services
      </Link>
      <ul
        className="dropdown-menu"
        aria-labelledby="servicesDropdown"
        style={dropdownMenuStyles}
      >
        <li>
          <Link
            to="/services/research-and-publications"
            className="dropdown-item"
          >
            Research and Publications
          </Link>
        </li>
        <li>
          <Link to="/services/training-and-workshops" className="dropdown-item">
            Trainings/Workshop
          </Link>
        </li>
        <li>
          <Link to="/services/certification" className="dropdown-item">
            Certification
          </Link>
        </li>
        <li>
          <Link to="/services/policy-development" className="dropdown-item">
            Policy Development
          </Link>
        </li>
        <li>
          <Link to="/services/consultancy" className="dropdown-item">
            Consultancy
          </Link>
        </li>
      </ul>
    </li>
  );
}
